/* roboto-cyrillic-ext-300-italic*/
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-display: swap;
  font-weight: 300;
  src: url('./files/roboto-cyrillic-ext-300-italic.woff2') format('woff2'), url('./files/roboto-all-300-italic.woff') format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* roboto-cyrillic-300-italic*/
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-display: swap;
  font-weight: 300;
  src: url('./files/roboto-cyrillic-300-italic.woff2') format('woff2'), url('./files/roboto-all-300-italic.woff') format('woff');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* roboto-greek-ext-300-italic*/
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-display: swap;
  font-weight: 300;
  src: url('./files/roboto-greek-ext-300-italic.woff2') format('woff2'), url('./files/roboto-all-300-italic.woff') format('woff');
  unicode-range: U+1F00-1FFF;
}
/* roboto-greek-300-italic*/
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-display: swap;
  font-weight: 300;
  src: url('./files/roboto-greek-300-italic.woff2') format('woff2'), url('./files/roboto-all-300-italic.woff') format('woff');
  unicode-range: U+0370-03FF;
}
/* roboto-vietnamese-300-italic*/
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-display: swap;
  font-weight: 300;
  src: url('./files/roboto-vietnamese-300-italic.woff2') format('woff2'), url('./files/roboto-all-300-italic.woff') format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* roboto-latin-ext-300-italic*/
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-display: swap;
  font-weight: 300;
  src: url('./files/roboto-latin-ext-300-italic.woff2') format('woff2'), url('./files/roboto-all-300-italic.woff') format('woff');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* roboto-latin-300-italic*/
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-display: swap;
  font-weight: 300;
  src: url('./files/roboto-latin-300-italic.woff2') format('woff2'), url('./files/roboto-all-300-italic.woff') format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* roboto-cyrillic-ext-300-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url('./files/roboto-cyrillic-ext-300-normal.woff2') format('woff2'), url('./files/roboto-all-300-normal.woff') format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* roboto-cyrillic-300-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url('./files/roboto-cyrillic-300-normal.woff2') format('woff2'), url('./files/roboto-all-300-normal.woff') format('woff');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* roboto-greek-ext-300-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url('./files/roboto-greek-ext-300-normal.woff2') format('woff2'), url('./files/roboto-all-300-normal.woff') format('woff');
  unicode-range: U+1F00-1FFF;
}
/* roboto-greek-300-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url('./files/roboto-greek-300-normal.woff2') format('woff2'), url('./files/roboto-all-300-normal.woff') format('woff');
  unicode-range: U+0370-03FF;
}
/* roboto-vietnamese-300-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url('./files/roboto-vietnamese-300-normal.woff2') format('woff2'), url('./files/roboto-all-300-normal.woff') format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* roboto-latin-ext-300-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url('./files/roboto-latin-ext-300-normal.woff2') format('woff2'), url('./files/roboto-all-300-normal.woff') format('woff');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* roboto-latin-300-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url('./files/roboto-latin-300-normal.woff2') format('woff2'), url('./files/roboto-all-300-normal.woff') format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
